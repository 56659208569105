import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseTsunami, faLaptop, faMagic } from '@fortawesome/free-solid-svg-icons';
import { faAndroid, faCreativeCommons, faUps } from '@fortawesome/free-brands-svg-icons'; // Importing faAndroid from free-brands-svg-icons


 const Services = ({servicesRef}) => {
  return (  
    <div className=" bg-backcolor " style={{Height:"70vh"}} ref={servicesRef} >
      <div className=" mx-auto py-10">
       
         
          <div className="flex justify-center ">
            <h1 className="h-4 w-4 border-l-4 border-t-4 border-customBlue"></h1>
            <h1 className="text-4xl text-customBlue">Our Services</h1>
            <h1 className="mt-7 h-4 w-4 border-b-4 border-r-4 border-customBlue"></h1>
          </div>
          
          <p className="text-black text-semibold text-center text-xl mt-5">
            We help you to build high-quality digital solutions and products as well as deliver a wide range of related professional services. <br/>We are providing world-class service to our clients.
          </p>
        
        <div className="grid grid-cols-1 md:p-24 px-5 md:grid-cols-3 gap-10 md:-mt-10">
          <div className="bg-white p-6 text-center shadow-sm rounded-xl border border-gray-200 bg-gradient-to-br hover:from-green-100 hover:via-lightblue-500 hover:to-pink-300">


            <span className="block text-blue-500 text-4xl mb-4">
              <FontAwesomeIcon icon={faLaptop} />
              
            </span>
            <h6 className="text-xl font-semibold mb-2">Web Solutions</h6>
            <p className="text-gray-700">
              Website Design & Development, Web Application Development, Maintenance, Redevelopment, E-Commerce Platform Development
            </p>
          </div>
          <div className="bg-white text-center p-8 shadow-sm rounded-2xl border  border-gray-200 bg-gradient-to-br hover:from-green-100 hover:via-lightblue-500 hover:to-pink-300">
            <span className="block text-green-500 text-4xl mb-4">
              <FontAwesomeIcon icon={faAndroid} />
            </span>
            <h6 className="text-xl font-semibold mb-2">Mobility Solutions</h6>
            <p className="text-gray-700">
              Mobile Application Development, Enterprise Application Development, M-Commerce & e-Wallets
            </p>
          </div>
          <div className="bg-white text-center p-8 shadow-md rounded-2xl border border-gray-200 bg-gradient-to-br hover:from-green-100 hover:via-lightblue-500 hover:to-pink-300">
            <span className="block text-purple-500 text-4xl mb-4">
              <FontAwesomeIcon icon={faMagic} />
            </span>
            <h6 className="text-xl font-semibold mb-2">Digital Solutions</h6>
            <p className="text-gray-700">
              Digital Marketing, Search Engine Optimizations
            </p>
          </div>
          <div className="bg-white text-center p-8 shadow-md rounded-2xl border border-gray-200 bg-gradient-to-br hover:from-green-100 hover:via-lightblue-500 hover:to-pink-300">
            <span className="block text-purple-500 text-4xl mb-4">
            <FontAwesomeIcon icon={faCreativeCommons}/>
            </span>
            <h6 className="text-xl font-semibold mb-2">Branding & Creative</h6>
            <p className="text-gray-700">
              Creative Logo Design, Brand Identity Development.
            </p>
          </div>
          <div className="bg-white text-center p-8 shadow-md rounded-2xl border border-gray-200 bg-gradient-to-br hover:from-green-100 hover:via-lightblue-500 hover:to-pink-300">
            <span className="block text-pink-500 text-4xl mb-4">
              <FontAwesomeIcon icon={faHouseTsunami} />
            </span>
            <h6 className="text-xl font-semibold mb-2">Hosting Solutions</h6>
            <p className="text-gray-700">
              Application & Web Hosting, Corporate Email Hosting, Public/Private Cloud Hosting, Domain Name Registration[.com/.net/.in/ etc......]
            </p>
          </div>
          <div className="bg-white text-center p-8 shadow-md rounded-2xl border border-gray-200 bg-gradient-to-br hover:from-green-100 hover:via-lightblue-500 hover:to-pink-300">
            <span className="block text-green-500 text-4xl mb-4">
              <FontAwesomeIcon icon={faUps} />
            </span>
            <h6 className="text-xl font-semibold mb-2">Start-Up Consulting</h6>
            <p className="text-gray-700">
              Product Ideation, Product Branding, Product Development, Product Marketing
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;