import React from 'react';
import client1 from '../Assets/AlgoWorks.png';  // Importing individual images
import client2 from '../Assets/AmigoDolls.png';
import client3 from '../Assets/ChakraLogo.png';
import client4 from '../Assets/JoyBharath.png';

export default function Clients() {
  return (
    <div>
      <div className='flex justify-center mt-10'>
        <div className="w-4 h-4 border-t-4 border-l-4 border-customBlue"></div>
        <span className='text-4xl font-semibold text-customBlue'>Our Clients</span>
        <div className="mt-6 w-4 h-4 border-b-4 border-r-4 border-customBlue"></div>
      </div>
      <span className='flex justify-center mt-10 text-md md:text-xl'>
        We Don't Just Build Softwares, We Build Your Business.
      </span>
      <div className='flex justify-center mt-10 mb-10'>
        <div className='grid grid-cols-2 sm:grid-cols-4 gap-6'>
          {/* Displaying the imported images with a slightly bigger size */}
          <div><img src={client1} alt='Client 1' className="w-32 h-32 object-contain" /></div>
          <div><img src={client2} alt='Client 2' className="w-32 h-32 object-contain" /></div>
          <div><img src={client3} alt='Client 3' className="w-32 h-32 object-contain" /></div>
          <div><img src={client4} alt='Client 4' className="w-32 h-32 object-contain" /></div>
        </div>
      </div>
    </div>
  );
}
