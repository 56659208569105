import React from 'react';
import priyanka from '../Assets/priyanka.jpg';
import deekshi from '../Assets/deekshi.jpg';
import vamshi from '../Assets/vamshi.jpg';
import sainath from '../Assets/sainath.jpg';
import karthik from '../Assets/karthik1.jpg';
import mansi from '../Assets/mansi.jpg';

export default function Team({ teamRef }) {
    return (
        <div
            className="bg-black text-white min-h-screen flex flex-col items-center"
            ref={teamRef}
        >
            {/* Header */}
            <div className="py-10">
                <div className="flex justify-center items-center">
                    <div className="h-4 w-4 border-l-4 border-t-4 border-blue-400"></div>
                    <h1 className="text-4xl text-blue-400 mx-4 font-bold">Our Team</h1>
                    <div className="h-4 w-4 border-b-4 border-r-4 border-blue-400"></div>
                </div>
            </div>

            {/* Cards */}
            <div className="grid gap-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 px-4">
                {[ 
                    { name: 'Priyanka', role: 'Backend Developer', img: priyanka },
                    { name: 'Deekshitha', role: 'Android Developer', img: deekshi },
                    { name: 'Vamshi', role: 'Backend Developer', img: vamshi },
                    { name: 'Sainath', role: 'Developer', img: sainath },
                    { name: 'Karthik', role: 'Fullstack Developer', img: karthik },
                    { name: 'Mansi', role: 'UI/UX Designer', img: mansi }
                ].map((member, index) => (
                    <div
                        key={index}
                        className="relative bg-white text-black rounded-lg shadow-xl w-64 p-6 transform hover:scale-105 transition duration-300 border-4 border-blue-500"
                    >
                        {/* Image */}
                        <div className="flex justify-center">
                            <img
                                src={member.img}
                                alt={member.name}
                                className="w-24 h-24 rounded-full shadow-md border-4 border-white"
                            />
                        </div>

                        {/* Text */}
                        <div className="mt-6 text-center">
                            <h2 className="text-lg font-bold">{member.name}</h2>
                            <p className="text-blue-500">{member.role}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
