import React from 'react'


function Wave() {
  return (
    <div className="relative w-full h-full">
    {/* First wave */}
    <div className="wave wave1"></div>

    {/* Second wave */}
    <div className="wave wave2"></div>

    {/* Third wave */}
    <div className="wave wave3"></div>
  </div>
  )
}

export default Wave