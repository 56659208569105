import React, { useEffect, useRef, useState } from 'react';
//import logo from '../Assets/algoLogo.png';
import logoname from '../Assets/logoname.png';

const NavBar = ({ servicesRef, aboutRef, ProjectRef, teamRef, contactRef, faqRef  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);
    
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
   
    const scrollToRef = (ref) => {
      if (ref && ref.current) {
        const yOffset = -55;
        const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
        setIsOpen(false); // Close the mobile menu after clicking on a link
      }
    };
   
    useEffect(() => {
      const closeMenu = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
          setIsOpen(false);
        }
      };
   
      document.addEventListener('click', closeMenu);
   
      return () => {
        document.removeEventListener('click', closeMenu);
      };
    }, []);

      return (
        <nav className='fixed top-0 left-0 w-full z-10 bg-customGray' ref={menuRef}>
            <div className='flex items-center justify-between'>
            <div className="">
                <div>
                    <img src={logoname} alt="logo" className="h-14 w-30" />
                </div>
            </div>
            <div className='hidden md:block'>
                <ul className="flex gap-10 text-xl mr-6 text-black font-semibold ">
                    <li className='hover:text-red-600 hover:underline ' >
                        <a href="#">Home</a>
                    </li>
                    <li className='hover:text-red-600 hover:underline' >
                        <a onClick={() => scrollToRef(aboutRef)} >About</a>
                    </li>
                    <li className='hover:text-red-600 hover:underline'>
                        <a onClick={() => scrollToRef(servicesRef)}>Services</a>
                    </li>
                    <li className='hover:text-red-600 hover:underline'>
                        <a onClick={() => scrollToRef(ProjectRef)} >Projects</a>
                    </li>
                    <li className='hover:text-red-600 hover:underline'>
                        <a onClick={() => scrollToRef(teamRef)}>Team</a>
                    </li>
                    {/* <li className='hover:text-red-600 hover:underline'>
                        <a href="#">Career</a>
                    </li> */}
                    <li className='hover:text-red-600 hover:underline'>
                        <a onClick={() => scrollToRef(contactRef)}>Contact</a>
                    </li>
                    <li className='hover:text-red-600 hover:underline'>
                        <a onClick={() => scrollToRef(faqRef)}>FAQ</a>
                    </li>
                </ul>
            </div>
            <div className="flex md:hidden">
                <button onClick={toggleMenu} className="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none focus:text-white">
                    <svg className={`${isOpen ? 'hidden' : 'block'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    <svg className={`${isOpen ? 'block' : 'hidden'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            </div>


            {/* Mobile Menu */}
            <div onClick={() => setIsOpen(false)} className={`${isOpen ? 'block' : 'hidden'} md:hidden  bg-gray-500`}>
                <div className="px-2 pt-2 pb-3 text-center justify-left sm:px-3">
                    <ul >
                <li className='hover:text-red-600 hover:underline mt-2 '>
                        <a href="#" >Home</a>
                    </li>
                    <li className='hover:text-red-400 hover:underline mt-2'>
                        <a href="#" >About</a>
                    </li>
                    <li className='hover:text-red-400 hover:underline mt-2'>
                        <a href="#">Services</a>
                    </li>
                    <li className='hover:text-red-400 hover:underline mt-2'>
                        <a href="#">Portfolio</a>
                    </li>
                    <li className='hover:text-red-400 hover:underline mt-2'>
                        <a href="#">Team</a>
                    </li>
                    <li className='hover:text-red-400 hover:underline mt-2'>
                        <a href="#">Career</a>
                    </li>
                    <li className='hover:text-red-400 hover:underline mt-2'>
                        <a href="#">Contact</a>
                    </li>
                    <li className='hover:text-red-400 hover:underline mt-2'>
                        <a href="#">FAQ</a>
                    </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
