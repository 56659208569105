import React from 'react'


function About({aboutRef})  {
  return (
    <div className=" " ref={aboutRef}>
      <div className=' flex justify-center mt-6'>
        <div className=" w-4 h-4 border-t-4 border-l-4 border-customBlue"></div>
        <span className='text-2xl lg:text-3xl font-semibold text-customBlue '>About</span>
        <div className="mt-6 w-4 h-4 border-b-4 border-r-4 border-customBlue "></div>
      </div>
      <div className='p-3'>
      <div className='text-center text-lg lg:text-2xl font-semibold mb-2'>We Make Creativity Work For Your Brand!</div>
      <div className="text-sm lg:text-xl  md:flex  text-center md:justify-center">
        <span className=''>Ours Is A Team Of Creatives That Is Brainstorming On Great Ideas,</span>
        <span className="font-semibold">All. The. Time.</span>
      </div>

      <div className='text-center text-sm lg:text-xl'>With Our Skills Put Together, You Get An Ensemble Capable Of Doing Anything And Everything Your Brand Needs.</div>
      <section className='flex justify-center items-center mt-6 mb-10 '>
        <button className=' border-2 rounded-3xl text-black hover:bg-blue-950 hover:text-white border-blue-950   px-8 py-0'>
          Read More
        </button>
      </section>
      </div>
    </div>
  )
}
export default About;
